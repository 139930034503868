// Components/CampingPresentation.js

import React from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, ScrollView, Dimensions, FlatList, Animated } from 'react-native';
import { getCampingInfos } from '../API/CampingApi';
import Store from '../Store/configureStore';
import PageItem from './PageItem';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

class CampingPresentation extends React.Component {


    constructor(props) {
        super(props);
        this.startValue = new Animated.Value(1);
        this.endValue = 1.1;
    }

    _displayDetailPage = (idpage, titre) => {

        this.props.navigation.navigate("PageDetail", { idpage: idpage, titre: titre })
    }




    _loadCampingPageTest(pages) {
        return (
            <View>
                <Text>COucou</Text>
            </View>
        )
    }



    render() {
        var state = Store.getState();
        var pages = state.campingpages;

        return (
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <View style={[styles.fullscreen, this.jewelStyle(state.campingconfig.homeBackgroundCouleur)]} >
                    <View style={styles.containerpresentation} >
                        <Image
                            style={styles.image}
                            source={{ uri: state.campingconfig.logoUrl }}
                        />
                        <Text style={[styles.campingName, { color: state.campingconfig.homeTexteCouleur }]}>{state.campingconfig.campingBaseline}</Text>
                    </View>
                    {this._scrolldown(pages, state)}
                </View>
                {this._loadCampingPage(pages, state)}
            </ScrollView>
        )
    }

    jewelStyle = function (campingBackgroundColor) {
        return {
            backgroundColor: campingBackgroundColor
        }
    }

    componentDidMount () {
        this.startAnimation()
      }

      startAnimation () {
        Animated.loop(
            Animated.spring(this.startValue, {
                toValue: this.endValue,
                friction: 0,
                useNativeDriver: true,
                loop: 1
            }),
            { iterations: -1 },
        ).start(() => {
            this.startAnimation()
          });
      }


    _scrolldown(pages, state) {

        if (pages.length > 0)
            return (
                <Animated.View style={[
                    {
                        transform: [
                            {
                                scale: this.startValue,
                            },
                        ],
                    },
                ]}
                >
                    <MaterialCommunityIcons style={styles.chevrondown} name="chevron-down" size={50} color={state.campingconfig.homeTexteCouleur} />
                </Animated.View>
            )
        else {
            return (
                <View></View>
            )
        }
    }

    _loadCampingPage(pages, state) {

        return (
            <View style={styles.list_container}>
                <FlatList
                    style={styles.list}
                    numColumns={3}
                    data={pages}
                    keyExtractor={(item) => item.pageid.toString()}
                    renderItem={({ item }) => <PageItem page={item} displayDetailPage={this._displayDetailPage} state={state} />}
                />
            </View>
        )
    }

}

let ScreenHeight = Dimensions.get("window").height;

const styles = StyleSheet.create(
    {
        square: {
        },
        main_container: {

            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
        },
        fullscreen: {
            height: ScreenHeight - 113,

        },
        textinput: {
            marginTop: 20,
        },
        image: {
            maxWidth: "80%",
            maxHeight: "40%",
            width: "80%",
            height: "40%",
            aspectRatio: 1,
            resizeMode: 'contain'
        },
        campingName: {
            fontSize: 22,
            padding: 10,
            textAlign: 'center'
        },
        list_container: {

            backgroundColor: "#f7f7f7"
            // justifyContent: 'space-around',
        },
        list: {
            //flex: 1,
            // flexDirection: 'row'
        },
        chevrondown: {
            alignSelf: "center",
        },
        containerpresentation: {
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        }
    }
)


//Text
//Image

export default CampingPresentation