// Components/SignInScreen.js

import React from 'react';

import {StyleSheet, View, Text, TextInput, Button, TouchableOpacity, Image } from 'react-native';
import Store from '../Store/configureStore';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import GLOBALS from './Globals';
import { testCampingId } from '../API/CampingApi';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import AsyncStorage from '@react-native-async-storage/async-storage';

//import { connect } from 'react-redux'

class SignInScreen extends React.Component {

  constructor(props) {
    super(props);

    if (GLOBALS.IDCAMPING) {
      this.state = {
        IdCamping: GLOBALS.IDCAMPING,
        messageContente: ""
      }
      this._setIdCamping();
    }
    else {

      this.state = {
        IdCamping: "",
        messageContente: ""
      }
      this._getDataLocalStarage();

    }
  }




  render() {

    if(GLOBALS.IDCAMPING == null || GLOBALS.IDCAMPING == ""){

    return (
      <View style={styles.main_container}>
        <Image
          style={styles.homeImage}
          source={require("../assets/logo_appli.png")}
        />
        <View style={styles.homeBarre}>
          <View style={styles.rect7Filler}></View>
          <View style={styles.rect7}></View>
        </View>

        <View style={styles.login_container}>
          <View style={styles.login_usernameRow}>
            <View style={styles.login_username}>
              <TextInput
                placeholder="Identifiant du Camping"
                placeholderTextColor="rgba(255,255,255,1)"
                secureTextEntry={false}
                style={styles.login_usernameInput}
                onChangeText={(text) => this.buildStateIdCamping(text)}
              ></TextInput>
            </View>
            {/* <View style={styles.login_qr}>
              <View style={styles.login_rect8}>
                <MaterialCommunityIcons name="qrcode" style={styles.login_icon23} size={53} />
              </View>
            </View> */}
          </View>
          <TouchableOpacity
            onPress={() => this._setIdCamping()}
            style={styles.login_button}
            activeOpacity={0.8}
          >
            <Text style={styles.login_text2}>Connexion</Text>
          </TouchableOpacity>
        </View>
        <Text style={styles.homeError}>{this.state.messageContente}</Text>
      </View>
    )
    }
    else{
      return (
        <View>
        </View>
      )
    }
  }

  //var IdCamping = "";
  //function buildStateIdCamping (text) {
  buildStateIdCamping = (text) => {
    //IdCamping = text;
    this.setState({ IdCamping: text });
  }


  _getDataLocalStarage = async () => {
    console.log('coucou');
    try {
      const campingidStorage = await AsyncStorage.getItem("storage_campingid");
      console.log('a');
      console.log(campingidStorage);
      if (campingidStorage !== null) {
        console.log('pas null');
        this.setState({ IdCamping: campingidStorage });
        this._setIdCamping();
      }
    } catch (e) {
        console.log(e);
      // saving error
    }
    console.log('Done.');
  }


  _saveDataLocalStarage = async (campingidStorage) => {
    try {
      await AsyncStorage.setItem('storage_campingid', campingidStorage);
      console.log('okstorage');
    } catch (e) {
        console.log('erreur storage');
        console.log(e);
      // saving error
    }
    console.log('Done.');
  }


  // _displayDetailActu = (idactus) => {
  _setIdCamping = (idactus) => {
    //state = Store.getState();
    testCampingId(this.state.IdCamping).then(data => {
      if (typeof (data) !== "undefined") {
        if (data.response == false && typeof (data.error) !== "undefined") {
          this.setState({ messageContente: data.error });
          return true;
        }
        if (data.response == true) {
          var campingidStorage = this.state.IdCamping;
          console.log(campingidStorage);
          this._saveDataLocalStarage(campingidStorage);
          const action = { type: 'SIGN_IN', token: 'dummy-auth-token', campingid: this.state.IdCamping, campingconfig: data.config, campingpages: data.page  };
          Store.dispatch(action);
          return true;
        } else {
          const action = { type: 'SIGN_OUT' }
          Store.dispatch(action);
          return true;
        }
      }
      return false;
    }).catch((error) => {
      const action = { type: 'SIGN_OUT' };
      Store.dispatch(action);
      return false;
    })
  }


}

const styles = StyleSheet.create({
  main_container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#065a62"
  },
  textinput: {
    marginTop: 20,
  },
  homeImage: {
    maxWidth: "80%",
    maxHeight: "40%",
    width: "80%",
    height: "40%",
    aspectRatio: 1,
    resizeMode: 'contain'
  },
  campingName: {
    fontSize: 20,
    padding: 10,
  },
  homeError: {
    fontSize: 20,
    padding: 10,
  },
  homeBarre: {
    width: 221,
    height: 5
  },
  rect7Filler: {
    flex: 1
  },
  rect7: {
    height: 8,
    backgroundColor: "rgba(31,175,191,1)",
    marginBottom: 6,
    marginLeft: 2,
    marginRight: 3
  },
  login_container: {
    width: 278,
    height: 231
  },
  
  login_username: {
    height: 60,
    backgroundColor: "rgba(191,129,75,1)",
    borderRadius: 6,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    width: 278,
    // width: 213,
    marginTop: 0
  },
  login_usernameInput: {
    height: 60,
    color: "rgba(255,255,255,1)",
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 15,
  },
  login_qr: {
    width: 56,
    height: 60,
    marginLeft: 9
  },
  login_rect8: {
    width: 56,
    height: 60,
    backgroundColor: "rgba(31,175,191,1)",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  login_icon23: {
    //color: "rgba(255,255,255,1)",
    //fontSize: 52,

    marginTop: 2,
    marginLeft: 2,
    color: "#fff"
  },
  login_usernameRow: {
    height: 60,
    flexDirection: "row",
    marginTop: 31
  },

  login_button: {
    marginTop: 9,
    height: 60,
    backgroundColor: "rgba(31,175,191,1)",
    borderRadius: 5,
    justifyContent: "center",
    marginBottom: 68
  },
  login_text2: {
    color: "rgba(255,255,255,1)",
    alignSelf: "center"
  }
})



export default SignInScreen
