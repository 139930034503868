// Components/ActuDetail.js

import React from 'react';
import { StyleSheet, View, Text, ActivityIndicator, ScrollView, useWindowDimensions } from 'react-native';
import { getCampingActuDetail } from '../API/CampingApi';
import HTML from "react-native-render-html";
import { Video, AVPlaybackStatus } from 'expo-av';
//import { connect } from 'react-redux'




class ActuDetail extends React.Component {

    constructor(props) {

        super(props)
        this.state = {
            actu: undefined, // Pour l'instant on n'a pas les infos du film, on initialise donc le film à undefined.
            isLoading: true // A l'ouverture de la vue, on affiche le chargement, le temps de récupérer le détail du film
        }
                this.video = React.createRef();
    }

    _displayLoading() {
        if (this.state.isLoading) {
            return (
                <View style={styles.activityIndicatorclassic}>
                    <ActivityIndicator size='large' />
                </View>
            )
        }
    }
    _lienvideo() {
        if (this.state.statepage != undefined) {
            if (this.state.statepage.page.lienvideo != null) {
                if (this.state.statepage.page.lienvideo.length > 0) {
                    return (
                        <Video
                            ref={this.video}
                            style={styles.video}
                            source={{
                                uri: this.state.statepage.page.lienvideo,
                            }}
                            useNativeControls
                            resizeMode="contain"
                            isLooping
                        // onPlaybackStatusUpdate={status => setStatus(() => status)}
                        />
                    )
                }
            }
        }
    }
    _displayActu() {
        if (this.state.actu != undefined) {
            const tagsStyles = {
                strong: {
                    textAlign: 'center',
                    fontStyle: 'italic',
                    color: 'teal'
                },
                h2: {
                    textAlign: 'center',
                    color: 'red',
                    fontSize: 20,
                    paddingRight: 15,
                    paddingLeft: 15,
                    marginBottom: 5,
                    marginTop: 25,
                    color: "#1c1e21"
                },
                p: {
                    paddingRight: 10,
                    paddingLeft: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    textAlign: 'justify',
                    fontSize: 16,
                    color: "#2f3338"
                },
            }
            return (
                <ScrollView style={styles.scrollview_container}>
                    <HTML source={{ html: this.state.actu.actus.actExcerpt }}
                        tagsStyles={tagsStyles}
                    />
                    {this._lienvideo()}
                </ScrollView>
            )
        }
    }

    componentDidMount() {


        getCampingActuDetail(this.props.route.params.idactus).then(data => {

            this.setState({
                actu: data,
                isLoading: false
            })
        }).catch((error) => {
            console.error(error);
        })
    }


    render() {

        const props = this.props;
        return (
            <View style={styles.main_container}>
                {this._displayLoading()}
                {this._displayActu()}
            </View>
        )
    }

}

const styles = StyleSheet.create({
    main_container: {
        flex: 1,
    }, 
    activityIndicatorclassic: {
        flex: 1,
        justifyContent: "center",
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10
      },
})


export default ActuDetail
