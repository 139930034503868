// Components/ActuItem.js

import React from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native';




class AgendaItem extends React.Component {
    render() {

        const {agenda, displayDetailAgenda} = this.props
        return (
                <TouchableOpacity
                    style={styles.main_container}
                    onPress={() => displayDetailAgenda(agenda.id, agenda.actuTitle)}
                    >
                    <Image
                        style={styles.image}
                        source={{uri: agenda.actumImage}}
                        />
                    <View style={styles.content_container}>
                        <View style={styles.header_container}>
                            <Text style={styles.title_text} numberOfLines={2}>{agenda.actuTitle}</Text>
                        </View>
                        <View style={styles.header_container}>
                            <Text style={styles.heure} numberOfLines={1}>{agenda.retourstart}</Text>
                        </View>
                              {agenda.retourend.length > 0 &&
                        <View style={styles.header_container}>
                            <Text style={styles.heure} numberOfLines={1}>{agenda.retourend}</Text>
                        </View>
                              }
                              <View
                                style={{
                                  borderBottomColor: '#b3b3b3',
                                  borderBottomWidth: 1,
                                }}
                              />
                        <View style={styles.description_container}>
                            <Text style={styles.description_text} numberOfLines={5}>{agenda.actExcerpt}</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                )
    }

}





const styles = StyleSheet.create({
    main_container: {
        height: 140,
        flexDirection: 'row'
    },
    image: {
        width: 130,
        height: 130,
        margin: 5
    },
    content_container: {
        flex: 1,
        margin: 5
    },
    header_container: {

        flexDirection: 'row'
    },
    title_text: {
        fontWeight: 'bold',
        fontSize: 18,
        flex: 1,
        flexWrap: 'wrap',
        paddingRight: 5
    },
    vote_text: {
        fontWeight: 'bold',
        fontSize: 26,
        color: '#666666'
    },
    heure: {
        fontSize: 14,
        color: '#2e2e2e',
        fontStyle: 'italic',
    },
    description_container: {
        flex: 7
    },
    description_text: {
        fontSize: 14,
        color: '#666666'
    },
    date_container: {
        flex: 1
    },
    date_text: {
        textAlign: 'right',
        fontSize: 14
    },
    favorite_image: {
        width: 25,
        height: 25,
        marginRight: 5
    }
})

export default AgendaItem
