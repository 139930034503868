// Components/ActuList.js

import React from 'react';
import { StyleSheet, FlatList, Text, ActivityIndicator, View, RefreshControl } from 'react-native';
import ActuItem from './ActuItem';
import { getCampingActus } from '../API/CampingApi';
import Store from '../Store/configureStore';
import { connect } from 'react-redux'

class ActuList extends React.Component {

  constructor(props) {

    super(props);
    this.page = 0 // Compteur pour connaître la page courante
    this.totalPages = 0 // Nombre de pages totales pour savoir si on a atteint la fin des retours de
    this.state = {
      actus: [],
      isLoading: false,
      refreshing: false,
      actuisLoading: false,
    }
  }

  _onRefresh() {
    this.setState({ refreshing: true });

    this.setState({ isLoading: true });
    getCampingActus(this.props.campingid, 1).then(data => {
      this.page = parseInt(data.infos.page);
      this.totalPages = data.infos.total_pages
      this.setState({
        actus: data.actus,
        isLoading: false,
        actuisLoading: true,
      })
      this.setState({ refreshing: false });
      //this.setState({actus: data.actus})
    }).catch((error) => {
      console.error(error);
    })

  }

  _loadCampingActus() {
    this.setState({ isLoading: true });
    getCampingActus(this.props.campingid, parseInt(this.page) + 1).then(data => {
      this.setState({ actuisLoading: true });

      if (typeof data.infos !== 'undefined') {
        this.page = parseInt(data.infos.page);
        this.totalPages = data.infos.total_pages
        this.setState({
          actus: [...this.state.actus, ...data.actus],
          isLoading: false
        })
      }
      //this.setState({actus: data.actus})
    }).catch((error) => {

    })
  }



  _displayDetailActu = (idactus, titre) => {

    this.props.navigation.navigate("ActuDetail", { idactus: idactus, titre: titre })
  }

  _displayLoading() {
    if (this.state.isLoading) {
      return (
        <View style={styles.activityIndicatorclassic}>
          <ActivityIndicator size='large' />
        </View>
      )
    }
  }


  componentDidMount() {
    this._loadCampingActus();
  }

  render() {
    if (this.state.actuisLoading == false) {
      return (
        <View style={[styles.activityIndicatorclassic]}>
          <ActivityIndicator size="large" />
        </View>
      )
    }
    else if (typeof this.state.actus !== 'undefined' && this.state.actus.length > 0) {
      return (
        <View style={styles.main_container}>
          <FlatList
            style={styles.list}
            data={this.state.actus}
            keyExtractor={(item) => item.id.toString()}
            refreshControl={
              <RefreshControl
                refreshing={this.state.refreshing}
                onRefresh={this._onRefresh.bind(this)}
              />
            }
            onEndReachedThreshold={0.2}
            onEndReached={() => {

              if (this.page < this.totalPages) {

                this._loadCampingActus();
              }
            }}
            renderItem={({ item }) => <ActuItem actus={item} displayDetailActu={this._displayDetailActu} />}
          />
          {this._displayLoading()}
        </View>
      )
    }
    else {
      return (
        <View>
          <Text style={styles.text_classic} numberOfLines={2}>Pas d'actualité pour le moment</Text>
        </View>
      )
    }

  }
}


const styles = StyleSheet.create({
  main_container: {
    flex: 1
  },
  list: {
    flex: 1
  },
  activityIndicatorclassic: {
    height: 60,

    padding: 10
  },
  text_classic: {
    color: "#666",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 30,
  },
})

const mapStateToProps = state => {
  return {
    campingid: state.campingid
  }
}

export default connect(mapStateToProps)(ActuList)

//export default ActuList
