// Store/Reducers/favoriteReducer.js



const initialState = {
                      isLoading: false,
                      isSignout: true,
                      userToken: null,
                      campingid: null,
                      campingconfig: {},
                      campingpages :  {},
                      }

function campingIdentificator(state = initialState, action) {
    let nextState
    switch (action.type) {
        case 'RESTORE_TOKEN':
            nextState = {
                ...state,
            userToken: action.token,
            isLoading: false,
            }
            return nextState || state
        case 'SIGN_IN':
            nextState = {
                ...state,
            isSignout: false,
            userToken: action.token,
            campingid: action.campingid,
            campingconfig: action.campingconfig,
            campingpages: action.campingpages,
            }
            return nextState || state
        case 'SIGN_OUT':
            nextState = {
                ...state,
            isSignout: true,
            userToken: null,
            campingid: null,
            isLoading: false,
            campingconfig: {},
            campingpages: {}
            }
            return nextState || state
        case 'CONFIG':
            nextState = {
                ...state,
            campingconfig: action.campingconfig,
            campingpages: action.campingpages,
            }
        case 'HOME_ERROR':
            nextState = {
                ...state,
            home_error: action.home_error,
            }
            return nextState || state
        default:
            return state
}
}


export default campingIdentificator