// Components/ActuList.js

import React from 'react';
import { StyleSheet, SectionList, Text, ActivityIndicator, View, RefreshControl } from 'react-native';
import AgendaItem from './AgendaItem';
import { getCampingAgenda } from '../API/CampingApi';
import Store from '../Store/configureStore';
import { connect } from 'react-redux'

class AgendaList extends React.Component {

  constructor(props) {
    super(props);
    this.lasteventid = null
    this.ilenrestecombien = null
    this.state = {
      agenda: [],
      isLoading: false,
      refreshing: false,
      agendaisLoading: false,
    }
  }

  _onRefresh() {
    this.setState({ refreshing: true });

    this.setState({ isLoading: true });
    getCampingAgenda(this.props.campingid, 1).then(data => {
      this.lasteventid = parseInt(data.infos.lasteventid);
      this.ilenrestecombien = parseInt(data.infos.ilenrestecombien);

      this.setState({
        agenda: data.agenda,
        isLoading: false,
        agendaisLoading: true,
      })

      this.setState({ refreshing: false });
      //this.setState({actus: data.actus})
    }).catch((error) => {
      console.error(error);
    })

  }

  jewelStyleBackground = function (campingBackgroundColor) {
    return {
      backgroundColor: campingBackgroundColor
    }
  }
  jewelStyleColor = function (color) {
    return {
      color: color
    }
  }

  _loadCampingAgenda() {
    this.setState({ isLoading: true });
    getCampingAgenda(this.props.campingid, parseInt(this.lasteventid)).then(data => {
      this.setState({ agendaisLoading: true });
      if (typeof data.infos !== 'undefined') {
        this.lasteventid = parseInt(data.infos.lasteventid);
        this.ilenrestecombien = parseInt(data.infos.ilenrestecombien);
        this.setState({
          agenda: [...this.state.agenda, ...data.agenda],
          isLoading: false
        })

      }
      else {
      }

      //this.setState({actus: data.actus})
    }).catch((error) => {
      console.error(error);
    })
  }



  _displayDetailAgenda = (idagenda, titre) => {

    this.props.navigation.navigate("AgendaDetail", { idagenda: idagenda, titre: titre })
  }

  _displayLoading() {
    if (this.state.isLoading) {
      return (
        <View style={styles.loading_container}>
          <ActivityIndicator size='large' />
        </View>
      )
    }
  }


  _renderSection = ({ section }) => {
    var state = Store.getState();

    return (

      <View style={[styles.boxday, this.jewelStyleBackground(state.campingconfig.agendaBackgroundCouleur)]}>
        <Text style={[styles.date, this.jewelStyleColor(state.campingconfig.agendaDateCouleur)]}>{section.title.date}</Text>
        <View style={styles.boxdetailday}>
          <Text style={styles.mois}>{section.title.mois}</Text>
          <Text style={styles.jour}>{section.title.jour}</Text>
        </View>
      </View>
    )
  }


  componentDidMount() {
    this._loadCampingAgenda();
  }

  render() {
    if (this.state.agendaisLoading == false) {
      return (
        <View style={[styles.activityIndicatorclassic]}>
          <ActivityIndicator size="large" />
        </View>
      )
    }
    else if (typeof this.state.agenda !== 'undefined' && this.state.agenda.length > 0) {
      return (
        <View style={styles.main_container}>
          <SectionList
            style={styles.list}
            sections={this.state.agenda}
            // keyExtractor={(item) => item.id.toString()}
            keyExtractor={(item, index) => item + index}
            onEndReachedThreshold={0.2}
            onEndReached={() => {

              if (this.ilenrestecombien != null && this.ilenrestecombien > 0) {

                this._loadCampingAgenda();
              }
            }}

            renderSectionHeader={this._renderSection}
            renderItem={({ item }) => <AgendaItem agenda={item} displayDetailAgenda={this._displayDetailAgenda} />}
          // renderItem={this._renderItem}
          />
          {this._displayLoading()}
        </View>
      )
    }
    else {
      return (
        <View>
          <Text style={styles.text_classic} numberOfLines={2}>l'agenda est vide pour le moment</Text>
        </View>
      )
    }


  }
}
//





const styles = StyleSheet.create({

  activityIndicatorclassic: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10
  },
  main_container: {
    flex: 1
  },
  list: {
    flex: 1
  },
  text_classic: {
    color: "#666",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 30,
  },
  date: {
    color: "#f35469",
    fontSize: 25,
    fontWeight: "bold",
    backgroundColor: "#fff",
    borderRadius: 50,

    width: 55,
    height: 55,
    textAlign: "center",
    paddingVertical: 10,
  },
  mois: {
    color: "#fff",
    fontSize: 15,
    fontWeight: "bold",
    //   textAlign: "center",
    paddingHorizontal: 20,
  },
  jour: {
    color: "#fff",
    fontSize: 25,
    fontWeight: "bold",
    //  textAlign: "center",
    paddingHorizontal: 20,
  },
  boxday: {
    padding: 8,
    backgroundColor: '#608cd2',
    flexDirection: 'row',
  },
  boxdetailday: {

    flexDirection: 'column'
  },
})

const mapStateToProps = state => {
  return {
    campingid: state.campingid
  }
}

export default connect(mapStateToProps)(AgendaList)

//export default ActuList
