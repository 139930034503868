// Components/ActuItem.js

import React from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native';





class PageItem extends React.Component {
    render() {
        
        const { page, displayDetailPage, state} = this.props;

        return (
            <TouchableOpacity
                style={styles.main_container}
                onPress={() => displayDetailPage(page.pageid, page.pageTitle)}
            >

                <View style={[styles.content_container, { backgroundColor:state.campingconfig.pageBackgroundCouleur }]}>
                    <Image
                        style={[styles.image, { backgroundColor:state.campingconfig.pageBackgroundCouleur }]}
                        source={{ uri: page.pageImage }}
                    />
                    <View style={styles.text_container}>
                        <Text style={[styles.title_text, { color: state.campingconfig.pageTexteCouleur }]}>{page.pageTitle}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        )

    }

}





const styles = StyleSheet.create({
    main_container: {
        flexDirection: 'column',
        width: "33%",
    },
    image: {
        height: 70,
        flex: 1,
        resizeMode: 'contain',
        margin: 9,
       backgroundColor: '#347ead',
    },
    content_container: {
        margin: 15,
        backgroundColor: '#347ead',
        borderRadius: 10,
        borderWidth: 0,
    },
    text_container: {
        flexDirection: 'row'
    },
    title_text: {
        fontWeight: 'regular',
        fontSize: 13,
        lineHeight: 15,
        flex: 1,
        flexWrap: 'wrap',
        textAlign: 'center',
        color: '#FFF',
        paddingBottom: 5
    },
    vote_text: {
        fontWeight: 'bold',
        fontSize: 26,
        color: '#666666'
    },
    description_container: {
        flex: 7
    },
    description_text: {
        fontStyle: 'italic',
        color: '#666666'
    },
    date_container: {
        flex: 1
    },
    date_text: {
        textAlign: 'right',
        fontSize: 14
    },
    favorite_image: {
        width: 25,
        height: 25,
        marginRight: 5
    }
})

export default PageItem
