// API/CampingApi.js
//const urlapi = "http://localhost/camping/index.php:80"
//const urlapi = "http://192.168.1.14/applicamp/public/api";

// npm start
// expo build:web

const urlapi = "https://admin.appcamp.fr/api";


export function getCampingActus(campingslug, page) {
    const url = urlapi+'/getlistactu?campingslug=' + campingslug + '&page=' + page;
    //const url = urlapi+'/?api=getListActu&idCamping=87589';


    return fetch(url)
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                return console.error(error)
            });
}


export function getCampingAgenda(campingslug, lasteventid = null) {
    const url = urlapi+'/getlistagenda?campingslug=' + campingslug + '&lasteventid=' + lasteventid;
    //const url = urlapi+'/?api=getListActu&idCamping=87589';
    return fetch(url)
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                return console.error(error)
            });
}



export function getCampingPageDetail(id) {
    const url = urlapi+'/getpagedetail?pageid=' + id;
    return fetch(url)
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                return console.error(error)
            });
}

export function getCampingActuDetail(id) {
    const url = urlapi+'/getactudetail?actuid=' + id;
    return fetch(url)
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                return console.error(error)
            });
}

export function getCampingAgendaDetail(id) {
    const url = urlapi+'/getagendadetail?agendaid=' + id;
    return fetch(url)
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                return console.error(error)
            });
}



export function testCampingId(campingId) {
    const url = urlapi+'/campinginfos?campingslug=' + campingId;
    return fetch(url)
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                return console.error(error)
            });
}


export function getCampingInfos(campingId) {
    const url = urlapi+'/campinginfos?campingslug=' + campingId;
        return fetch(url)
                .then((response) => {
                    return response.json();
                })
                .catch((error) => {
                    return console.error(error)
                });
}